import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import removeHTMLTags from '../utils/remove-html-tags'
import { Helmet } from 'react-helmet'

const Seo = ({
  //custom seo
  title,
  description,
  image,
  //global settings from datoCMS
  noIndex,
  globalSeo,
  faviconMetaTags,
  locale,
}) => {
  const resultTitle = `${(title && removeHTMLTags(title)) ||
    globalSeo.fallbackSeo.title} ${globalSeo.titleSuffix}`
  const resultddescription =
    (description && removeHTMLTags(description)) ||
    globalSeo.fallbackSeo.description
  const resultImage = image || globalSeo.fallbackSeo.image

  return (
    <Helmet>
      <html lang="ja" />
      <meta
        content={noIndex ? 'noindex,nofollow' : 'index,follow'}
        name="robots"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimal-ui, viewport-fit=cover"
      />
      <title>{resultTitle}</title>
      <meta name="description" content={resultddescription} />
      <meta property="og:title" content={resultTitle} />
      <meta property="og:description" content={resultddescription} />
      {/*<meta property="og:url" content="https://www.pilsnerurquellelearning.com" />*/}
      <meta property="og:site_name" content={globalSeo.siteName} />
      <meta property="og:image" content={resultImage.url} />
      <meta property="og:image:width" content={resultImage.width} />
      <meta property="og:image:height" content={resultImage.height} />
      <meta property="og:locale" content={locale} />
      <meta property="og:image:type" content={`image/${resultImage.format}`} />

      {faviconMetaTags.tags.map(({ attributes, tagName }, key) =>
        tagName === 'link' ? (
          <link
            type={attributes.type}
            rel={attributes.rel}
            href={attributes.href}
            sizes={attributes.sizes}
            key={key}
          />
        ) : (
          <meta
            property={attributes.name}
            content={attributes.content}
            key={key}
          />
        )
      )}
    </Helmet>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsSite {
          globalSeo {
            titleSuffix
            fallbackSeo {
              title
              description
              image {
                url
                width
                height
                format
              }
            }
            siteName
          }
          noIndex
          locale
          faviconMetaTags {
            tags {
              tagName
              attributes {
                rel
                sizes
                href
                name
                content
                type
              }
            }
          }
        }
      }
    `}
    render={data => <Seo {...data.datoCmsSite} {...props} />}
  />
)
